@tailwind base;
@tailwind components;
@tailwind utilities;

.marketing-boxes img {
    max-width: 150px;
    object-fit: contain;
    width: 100%;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    margin-bottom: 10px;
}

.hover-container {
    position: relative;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* Smoother transition */
    cursor: pointer;
}

.hover-img,
.hover-title {
    transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.2s ease-in-out;
}

.hover-description {
    opacity: 0;
    transform: translateY(15px);
    /* Slightly increased to enhance the transition */
    transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
    /* Smoother description transition */
}

.hover-container:hover .hover-img {
    transform: translateY(-12px);
    /* More noticeable movement */
    opacity: 1;
    /* Make it fully visible */
}

.hover-container:hover .hover-title {
    transform: translateY(-12px);
    opacity: 1;
    /* Full opacity */
}

.hover-container:hover .hover-description {
    opacity: 1;
    transform: translateY(0);
    /* Bring it to its original position smoothly */
}

/* Responsive design */
@media (max-width: 768px) {
    .hover-container {
        padding: 1rem;
    }

    .hover-img {
        width: 80%;
        /* Adjust image size on smaller screens */
    }

    .hover-title {
        font-size: 16px;
        /* Adjust font size for mobile */
    }

    .hover-description {
        font-size: 14px;
        /* Smaller text for mobile screens */
    }
}

@media (max-width: 480px) {
    .hover-container {
        padding: 0.5rem;
    }

    .hover-title {
        font-size: 14px;
    }

    .hover-description {
        font-size: 12px;
    }
}

/* arrow animations */

.graph__wrapper {
    position: absolute;
    left: 0;
    top: -10px !important;
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 3s linear forwards;
    animation-iteration-count: 1;
    animation-delay: 1s;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.dashed {
    stroke-dasharray: 5, 12;

}

#arrow {
    animation: arrow 2s linear forwards;
    opacity: 0;
}

@keyframes arrow {
    to {
        opacity: 1;
    }
}

.ifrm {
    border: none;
    width: 100%;
    margin-top: 50px;
}

.arrow-one {
    width: 541px !important;
    height: 257px;
}

.arrow-two {
    width: 300px;
    height: 208px;
    left: 20px;
    bottom: -74px;
}

.make-gap{
    gap: 50px;
}
.arrow-three {
    width: 700px;
    height: 400px;
    position: absolute;
    top: 130%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.arrow-four {
    width: 200px;
    height: 300px;
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.arrow-five {
    width: 300px;
    height: 300px;
}

.description-title {
    padding-bottom: 60px;
}

@media screen and (min-width: 480px) {
    .logo-slide {
        margin-left: 10px;
    }
}

@media screen and (max-width: 768px) {
    .description-title {
        padding-bottom: 10px;
    }

    .arrow-one {
        position: absolute;
        right: 0px;
        bottom: -20px;
        width: 200px !important;
        height: 100px;
    }

    .arrow-two {
        width: 100px;
        height: 322px;
        bottom: 17px;
        display: none;
    }

    .arrow-three {
        width: 309px;
        overflow: hidden !important;
        top: 117% !important;
    }

    .arrow-four {
        width: 100px;
        top: 94% !important;
    }
    .make-gap{
        gap: 16px !important;
    }

    .arrow-five {
        width: 150px !important;
        height: auto;
        top: -192px;
        right: -6px;
    }
}