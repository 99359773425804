* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.App {
    overflow: hidden;
}

body {
    font-family: "Helvetica", sans-serif !important;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Compressed.woff2') format('woff2'),
        url('./fonts/Helvetica-Compressed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Bold.woff2') format('woff2'),
        url('./fonts/Helvetica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Light.woff2') format('woff2'),
        url('./fonts/Helvetica-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica.woff2') format('woff2'),
        url('./fonts/Helvetica.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-BoldOblique.woff2') format('woff2'),
        url('./fonts/Helvetica-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/HelveticaRounded-Bold.woff2') format('woff2'),
        url('./fonts/HelveticaRounded-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Oblique.woff2') format('woff2'),
        url('./fonts/Helvetica-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}



.home-banner {
    align-items: center;
    background: black;
    padding-top: 40px;
    min-height: 50vh;
    margin-bottom: 17.8890876565vw;
    position: relative;
    transition: all 1.5s ease;
    width: 100%;
}

.mobile-home-banner {
    min-height: 100vh;
    animation: slideDown 2s forwards;
}

.home-banner h1 {
    font-size: 18px;
    letter-spacing: -.6px;
    line-height: 30px;
    opacity: 1;
    padding: 80px 20px;
    position: relative;
    color: white;
    text-align: center;
    transition: opacity 1s ease, transform 1s ease;
    z-index: 2;
    max-width: 100%;
}

.home-banner__umbrella {
    animation: bounce 2.5s infinite ease-in-out;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100vw;
    z-index: 1
}

.home-banner__umbrella svg {
    display: block;
    margin: 0
}

.home-banner__umbrella--mobile {
    display: block
}

.home-banner__umbrella--desktop {
    display: none
}

main.hide .home-banner h1 {
    opacity: 0
}

main.finished .home-banner {
    height: 460px
}


@media (max-width: 767px) {


    .hero-container-max {
        gap: 50px !important;
    }

    .order-one {
        order: 3;
    }

    .order-two {
        order: 1;
    }

    .order-three {
        order: 2;
    }
    .mobile-home-mob {
        min-height: 100vh;
        animation: slideUp 2s forwards;
    }


}

@media(min-width:900px) {
    .home-banner__umbrella--mobile {
        display: none
    }
}


@media(min-width:900px) {
    .home-banner h1 {
        font-size: 26px;
        letter-spacing: -1px;
        line-height: 38px;
        max-width: 550px;
        width: 100%;
        padding-top: 120px;
        margin: 0 auto;
    }
}

@media(min-width:900px) {
    main.hide .home-banner {
        margin-bottom: 0
    }
}




@media(min-width:900px) {
    .home-banner__umbrella--desktop {
        display: block
    }
}

@media(min-width:900px) {
    .contact-banner__umbrella--mobile {
        display: none
    }
}


/*=== Trigger  ===*/
.animate {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes bounce {
    0% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-10px)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes slideDown {
    0% {
        min-height: 100vh;
    }

    100% {
        min-height: 50vh;
    }
}

@keyframes slideUp {
    0% {
        min-height: 100vh;
    }

    100% {
        min-height: 30vh;
    }
}

